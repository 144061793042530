@font-face {
    font-family: "MontSerratSemiBold";
    font-display: swap;
    src: local("MontSerratSemiBold"), url("./fonts/Montserrat-SemiBold.ttf");
}
@font-face {
    font-family: "MontserratMedium";
    font-display: swap;
    src: local("MontserratMedium"), url("./fonts/Montserrat-Medium.ttf");
}
@font-face {
    font-family: "MontSerratRegular";
    font-display: swap;
    src: local("MontserratMedium"), url("./fonts/Montserrat-Regular.ttf");
}
@font-face {
    font-family: "MontserratBold";
    font-display: swap;
    src: local("MontserratBold"), url("./fonts/Montserrat-Bold.ttf");
}
