.footer-container {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  border-top: 2px solid #0072fd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer-left-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .footer-logo {
      height: 50px;
    }
  }

  .footer-navigation {
    display: flex;
    font-size: 14px;
    margin-left: 20px;
    .footer-nav {
      margin: 5px;
      color: #b5b2b2 !important;
      transition: color ease-in-out 0.3s;
      cursor: pointer;
    }
    .footer-nav:hover {
      color: #0072fd !important;
    }
  }
  .footer-right-block {
    .footer-icon {
      display: flex;
      justify-content: center;
      color: #ffffff;
      .icon-container {
        margin: 10px;
        transition: 0.5s;
      }
      .icon-container:hover {
        color: #0072fd !important;
      }
    }
    .footer-text {
      display: flex;
      color: #b5b2b2;
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer-container {
    .footer-navigation, .footer-right-block {
      display: none;
    }
  }
}
