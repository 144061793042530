@import '../../../styles/constants/other.scss';
@import '../../../styles/mixins.scss';

.header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: $header-height;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: top 0.3s;
  box-shadow: 0 4px 2px -2px #0072fd;
  align-items: center;
  .header-logo-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    width: 200px;
    // background: rgba(255,255,255,0.5);
    svg {
      width: 150px;
      height: $header-height;
    }
  }
  .anticon {
    margin-left: 5px;
    margin-top: 5px;
  }
  .header-rigth-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    .header-content {
      // margin-right: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      transition: 1s border-bottom;
      &-tab a {
        margin: 0 10px 0 0;
      }
    }

    .city-container {
      // width: 300px;
      font-family: 'MontSerratSemiBold';
      color: #343340;
      font-size: 18px;
      font-weight: bold;
      width: 30%;
      max-width: 210px;
      justify-content: center;
    }
    .left {
      // width: 110px;
      font-family: 'MontSerratSemiBold';
      font-weight: 600;
      color: #343340;
      font-size: 18px;
      .language-container {
        // width: 110px;
        font-family: 'MontserratMedium';
        color: #343340;
      }
    }
  }
  .header-content-tab {
    position: relative;
    margin: 0 15px;
    .badge {
      position: absolute;
      background: #f50;
      font-size: 14px;
      padding: 0.5px 6px;
      border-radius: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -20px;
      top: -10px;
      color: #fff;
    }

    .header-content-border-bottom {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.85);
    }
  }
  .border {
    border-right: 1px solid #d9d9d9;
    height: 20px;
  }
  .header-content-tab:hover {
    .header-content-border-bottom {
      display: block;
    }
  }
  .header-content {
    .lng-container {
      align-items: center;
      color: #3433408c;
      p {
        margin-bottom: 3px;
        cursor: pointer;
      }

      .active {
        color: #343340;
      }
    }
  }

  .header-menu-container {
    display: none;
  }
  .header-social-icons {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .header {
    display: flex;
    .header-logo-container {
      @include center;
      padding: 2px 10px;
      width: 165px;
      svg {
        width: 125px;
      }
    }
    .header-rigth-container {
      .header-content {
        margin-right: 0;
        justify-content: center;
        font-size: 20px;
        display: flex;
        color: rgba(0, 0, 0, 0.75);
        background: rgb(255, 255, 255);
        justify-content: center !important;
        border: 1px solid rgb(221, 221, 221);
        transition: 1s opacity;
        width: 100%;
        margin-bottom: unset;
      }
    }
  }
  .cart-container {
    color: rgba(0, 0, 0, 0.75) !important;
  }
  .header .header-rigth-container {
    display: flex;
    padding-right: 10px;
  }
  .header .header-menu-container {
    display: block;
    margin-right: 25px;

    &_content {
      @include center;
      gap: 24px;
    }

    .header-content-tab {
      margin: 0;
    }
  }
}
