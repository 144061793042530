@import '../../../styles/constants/other.scss';

.container {
  margin-top: $header-height;
  position: relative;
  .referrer_container_baner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #fbf3f3;
    &_firstImg {
      width: 70px;
      align-self: flex-start;
    }
    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
      max-width: 730px;
      text-align: center;
      margin: 10px;
    }
    &_lastImg {
      width: 70px;
      align-self: flex-end;
    }
  }
  .aboutUs {
    background: #fff;
    flex-direction: column;
    padding: 30px 40px 40px;
    h2 {
      font-size: 2rem;
      line-height: 2rem;
    }
    h4 {
      margin-top: 20px;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
  .referrer_image_container {
    position: relative;

    .referrer_image {
      width: 100%;
      height: 470px;
      object-fit: cover;
      display: block;
    }

    .referrer_text_overlay {
      position: absolute;
      top: 10%;
      left: 10%;
      color: white;
      background: rgba(0, 0, 0, 0.5); // Semi-transparent background
      padding: 20px;
      border-radius: 10px;
      max-width: 80%;

      h2 {
        margin-bottom: 15px;
      }

      ul {
        list-style: disc;
        margin: 0 0 15px 20px;

        li {
          margin: 5px 0;

          strong {
            color: #ffd700; // Highlighted text
          }
        }
      }

      .closing-text {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .referrer_contact_us_btn {
    position: absolute;
    bottom: 40px;
    left: calc(50% - 70px);
    width: 140px;
    border-radius: 20px;
    outline: none;
    border-color: unset;
    background-color: #0072fd;
    border: unset;
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    transition: background-color ease-in-out 0.3s;
    &:hover {
      background-color: #3a89e9;
    }
  }
  .referrer_container_howItWorks {
    background-color: #e1f0ff;
    flex-direction: column;
    padding: 30px 40px 40px;
    h2 {
      font-size: 2rem;
      line-height: 2rem;
      margin: 15px 15px 30px;
    }
    .card_container {
      display: flex;
      justify-content: center;
      .card {
        width: 25%;
        background: #fff;
        margin: 10px 20px;
        border-radius: 8px;
        padding: 30px;
        h4 {
          margin-bottom: 5px;
          font-size: 16px;
        }
        li {
          font-size: 14px;
          font-family: MontSerratRegular;
          margin-bottom: 5px;
        }
      }
    }
  }
  .referrer_container_commission {
    flex-direction: column;
    padding: 30px 40px 40px;
    h2 {
      font-size: 2rem;
      line-height: 2rem;
      margin: 15px 15px 30px;
    }
    &_item {
      display: flex;
      padding: 10px 30px;
      border-radius: 8px;
      justify-content: center;
      margin-bottom: 10px;
      &_level {
        padding: 25px;
        background-color: #e1f0ff;
        border-radius: 8px 0 0 8px;
        width: 280px;
      }
      &_value {
        padding: 25px;
        width: 420px;
        background-color: #304c84;
        color: #fff;
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .referrer_container_contactUs {
    flex-direction: column;
    padding: 30px 40px 40px;
    h2 {
      font-size: 2rem;
      line-height: 2rem;
      margin: 15px 15px 30px;
    }
    .socialIcons {
      display: flex;
      margin: 10px 0;
      .icon-container {
        margin-right: 20px;
      }
    }
    .contact_icon {
      margin-right: 5px;
    }
  }
}
@media (max-width: 768px) {
  .container .referrer_container_baner h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .container .referrer_image_container .referrer_text_overlay {
    position: static;
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }
  .container .referrer_container_howItWorks .card_container {
    flex-wrap: wrap;
  }
  .container .referrer_container_howItWorks .card_container .card {
    width: 100%;
  }
  .container .referrer_container_commission_item_level {
    display: flex;
    align-items: center;
  }
  .container .referrer_container_contactUs {
    padding: 30px 40px 90px;
  }
}
