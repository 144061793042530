body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 25px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  white-space: pre-wrap !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 10px 16px 12px 24px;
  border-radius: 8px !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
}

:where(.css-m4timi).ant-picker .ant-picker-input > input {
  font-size: 16px !important;
}
// :where(.css-dev-only-do-not-override-acm2ia).ant-table-wrapper .ant-table-pagination.ant-pagination {
//   margin: 0px 0px 10px;
// }
