.static {
    position: absolute;
    right: 0vw;
    top: calc(35vh + 100px);
    z-index: 99;
    .icon-container {
      margin: 3vw;
      transition: .2s;
    }
    .icon-container:hover {
      color: #0072fd !important;
    }
  }
  @media screen and (max-width: 800px) {
    .social-container {
      display: none;
    }
}