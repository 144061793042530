
@import '../../../styles/constants/other.scss';
.carousel-container {
  max-width: 800px;
  margin: $header-height auto 25px;
  text-align: center;
}

/* Main Image */
.main-image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #0000000a;
}

/* Thumbnail Carousel */

.thumbnail-carousel .slick-slide {
  padding: 0 5px;
}

.thumbnail-carousel img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.thumbnail-carousel .thumbnail.active img {
  border-color: #007bff;
}

/* Placeholder Loaders */
.placeholder {
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
}

.placeholder-loader {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.main-image.placeholder {
  height: 400px;
}

.thumbnail.placeholder {
  height: 100px;
}
