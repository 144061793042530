.modal-conatiner {
  width: 100vw !important;
  top: 0px !important;
  .ant-modal-header {
    border-bottom: unset;
    background-color: unset;
    .ant-modal-title {
      width: 100px;
    }
  }

  .ant-modal-content {
    height: 100vh;
    .ant-modal-close-x {
      width: 100px;
      font-size: 30px;
      color: #333;
      line-height: 70px;
    }
  }
  .ant-modal-body {
    padding: unset;
    display: flex;
    font-size: 20px;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    color: #333;
    .modal-city-container {
      text-align: center;
      // margin-bottom: 5vh;
      border-bottom: 1px solid #333;
      width: 80%;
      .city {
        margin-bottom: 2vh;
      }
    }
    .modal-body-container {
      margin-bottom: 5vh;
      // border-bottom: 1px solid #333;
      width: 80%;
      text-align: center;
      .header-content-tab {
        margin-bottom: 2vh;
      }
    }

    .border-bottom {
      border-bottom: 2px solid #0072fd;
    }

    .language-container {
      width: 80%;
      color: #333 !important;
      padding: 10px;
      .lng-container {
        text-align: center;
        .header-content-tab {
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 2vh !important;
          cursor: pointer;
        }
      }
    }
  }
  .header-modal-social-icons-conatiner {
    .social-container {
      margin-bottom: 5vh;
      display: flex;
      .icon-container {
        width: 30px !important;
        height: 30px !important;
        margin: 0 5px;
      }
    }
  }
  .header-modal-footer {
    display: flex !important;
    flex-direction: column;
    padding: 20px 0;
    width: 100%;
  }
  .footer-container {
    margin-top: unset !important;
    background: unset;
  }
}
.ant-modal-wrap {
  overflow: hidden !important;
}

@media only screen and (max-width: 1280px) {
  .modal-conatiner {
    .social-container {
      display: flex;
    }
  }
}

@media only screen and (max-width: 800px) {
  .ant-modal-close {
    z-index: 10000000 !important;
  }
  .modal-conatiner {
    max-width: 100vw !important;
    margin: unset !important;
  }
  .ant-modal-content {
    border-radius: 0 !important;
    .ant-modal-close {
      right: 40px;
    }
  }
}
