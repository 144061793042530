* {
  box-sizing: border-box;
  font-family: 'MontSerratSemiBold';
}

html,
body {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: separate;
  border-collapse: collapse;
  border-spacing: 0;
}

table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}

a img {
  border: 0;
}

:focus {
  outline: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  outline: 0;
  text-decoration: none;
  color: inherit;
}

p {
  cursor: default;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.app-content-container {
  min-height: calc(100vh - 85px);
  max-width: 1480px;
  margin: 0 auto;
}
.icon-image {
  width: 15px;
  height: 15px;
}
.link {
  cursor: pointer;
}
.btn {
  outline: none;
  border: none;
}

.btn-style {
  border-radius: 20px;
  background-color: #0072fd;
  transition: background-color ease-in-out 2s;
  color: #ffffff;
  padding: 5px 10px;
}
.btn-style:hover {
  background-color: #08a2f0;
}
a {
  color: unset !important;
}
a:hover {
  color: unset;
}
.tab-icon {
  margin-right: 5px;
}
input {
  font-size: 16px !important;
}
@import './fonts';
