/**
 * Other style variables
 */
$border-radius: 4px;
$header-height: 70px;
$header-height2X: 96px;
$sidebar-width: 200px;
$rightSidebar-width: 300px;
$sidebar-width2X: 500px;
$secondary-header: 55px;
